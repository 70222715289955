
function nodeListToElements(list: NodeListOf<HTMLElement>): HTMLElement[] {
    const ret = [];
    for (let i = 0; i < list.length; i++) {
        ret.push(list[i]);
    }
    return ret;
}

function hideAll(elements: HTMLElement[]): void {
    for (let element of elements) {
        element.style.display = "none";
    }
}

function showAll(elements: HTMLElement[]): void  {
    for (let element of elements) {
        element.style.display = "inline-table";
    }
}

document.addEventListener("DOMContentLoaded", event => {
    const visibilityTargets = nodeListToElements(document.querySelectorAll(`[data-visible-if]`));
    hideAll(visibilityTargets);

    const visibleEauAss: HTMLElement[] = [];
    const visibleEauAnc: HTMLElement[] = [];
    const visibleOnlyEau: HTMLElement[] = [];
    const visibleOnlyAss: HTMLElement[] = [];
    const visibleOnlyAnc: HTMLElement[] = [];

    for (let element of visibilityTargets) {
        switch (element.getAttribute("data-visible-if")) {
        case 'eau+ass':
            visibleEauAss.push(element);
            break;
        case 'eau+anc':
            visibleEauAnc.push(element);
            break;
        case 'only-eau':
            visibleOnlyEau.push(element);
            break;
        case 'only-ass':
            visibleOnlyAss.push(element);
            break;
        case 'only-anc':
            visibleOnlyAnc.push(element);
            break;
        }
    }

    const buttonEau = document.querySelector(`#button-eau`) as HTMLButtonElement;
    const buttonAss = document.querySelector(`#button-ass`) as HTMLButtonElement;
    const buttonAnc = document.querySelector(`#button-anc`) as HTMLButtonElement;

    let eauActive = false;
    let assActive = false;
    let ancActive = false;

    const updateAll = () => {
        hideAll(visibilityTargets);
        if (eauActive) {
            if (assActive) {
                showAll(visibleEauAss);
            } else if (ancActive) {
                showAll(visibleEauAnc);
            } else {
                showAll(visibleOnlyEau);
            }
        } else if (assActive) {
            showAll(visibleOnlyAss);
        } else if (ancActive) {
            showAll(visibleOnlyAnc);
        }

        // Update buttons status :
        if (buttonEau) {
            buttonEau.setAttribute('data-active', eauActive ? '1' : '0');
        }
        if (buttonAss) {
            buttonAss.setAttribute('data-active', assActive ? '1' : '0');
        }
        if (buttonAnc) {
            buttonAnc.setAttribute('data-active', ancActive ? '1' : '0');
        }

    };

    // Default behaviour.
    if (buttonEau) {
        eauActive = true;
        if (buttonAss) {
            assActive = true;
        } else if (buttonAnc) {
            ancActive = true;
        }
    } else if (buttonAss) {
        assActive = true;
    } else if (buttonAnc) {
        ancActive = true;
    }

    // Render things correctly.
    updateAll();

    if (buttonEau) {
        buttonEau.addEventListener("click", event => {
            event.stopPropagation();
            event.preventDefault();

            if (!assActive && !ancActive) {
                return; // On ne fait rien, il faut toujours 1 truc visible.
            }

            eauActive = !eauActive;
            updateAll();
        });
    }

    if (buttonAss) {
        buttonAss.addEventListener("click", event => {
            event.stopPropagation();
            event.preventDefault();

            if (assActive) {
                if (eauActive) {
                    assActive = false;
                } else if (buttonAnc) {
                    assActive = false;
                    ancActive = true;
                }
            } else {
                assActive = true;
                ancActive = false;
            }

            updateAll();
        });
    }

    if (buttonAnc) {
        buttonAnc.addEventListener("click", event => {
            event.stopPropagation();
            event.preventDefault();

            if (ancActive) {
                if (eauActive) {
                    ancActive = false;
                } else if (buttonAss) {
                    ancActive = false;
                    assActive = true;
                }
            } else {
                ancActive = true;
                assActive = false;
            }

            updateAll();
        });
    }

    // More/Less info
    const buttonToggleDetail = document.querySelector(`.link-view-more`) as HTMLButtonElement;
    const details = document.querySelectorAll(`.prix-detail`);

    if (buttonToggleDetail && details) {

        const toggleDetail = () => {
            if (!buttonToggleDetail.classList.contains('collapsed')) {
                details.forEach(e => {
                    e.classList.add('hidden');
                });
            } else {
                details.forEach(e => {
                    e.classList.remove('hidden');
                });
            }
        };

        buttonToggleDetail.addEventListener("click", event => {
            toggleDetail();
        });
    }

    // PDF download link
    const pdfLink = document.querySelector(`button.prix-pdf.icon-file`) as HTMLButtonElement;
    if (pdfLink) {
        pdfLink.addEventListener("click", event => {
            event.stopPropagation();
            event.preventDefault();

            let target = 'eau';
            if (eauActive) {
                if (assActive) {
                    target = 'eau-ass';
                } else if (ancActive) {
                    target = 'eau-anc';
                }
            } else if (assActive) {
                target = 'ass';
            } else if (ancActive) {
                target = 'anc';
            }

            window.open(pdfLink.getAttribute('data-url') + "?target=" + target,"_self");
        });
    }
});
