// import Ellipsis from "ftellipsis";
import * as React from "react";
import * as ReactDOM from "react-dom";

// Load actus, yes.
function request(url: string): Promise<XMLHttpRequest> {
    return new Promise<XMLHttpRequest>((resolve: (req: XMLHttpRequest) => void, reject: (err: any) => void) => {
        let req = new XMLHttpRequest();
        req.open('GET', url);
        req.addEventListener("load", function () {
            if (this.status !== 200) {
                reject(`${this.status}: ${this.statusText}`)
            } else {
                resolve(req);
            }
        });
        req.addEventListener("error", function () {
            reject(`${this.status}: ${this.statusText}`);
        });
        req.send();
    });
}

interface Actu {
    readonly createdAt: number; // Timestamp actually
    readonly id: number;
    readonly imageUri?: string;
    readonly teaser: string;
    readonly title: string;
    readonly uri: string;
}

interface ActuLinkProps {
    readonly href: string;
}

interface ActuListProps {
    readonly allUrl: string;
    readonly limit: number;
    readonly loadUrl: string;
}

interface ActuListState {
    readonly actus: Actu[];
    readonly canLoadMore: boolean;
    readonly loading: boolean;
    readonly error: boolean;
    readonly latestCreatedAt?: number;
    readonly latestId?: number;
}

class ActuLink extends React.Component<ActuLinkProps> {
    render() {
        return <a role="button" className="btn btn-primary" href={this.props.href}>Charger plus d'articles</a>;
    }
}

class ActuList extends React.Component<ActuListProps, ActuListState> {

    constructor(props: ActuListProps) {
        super(props);

        this.state = {actus: [], canLoadMore: true, loading: true, error: false};
        this.onLoadMoreClick = this.onLoadMoreClick.bind(this);
    }

    static getDerivedStateFromError(error: any) {
        return {error: true};
    }

    componentDidMount() {
        this.refresh();
    }

    onClickNoop(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();
    }

    onLoadMoreClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault();

        this.refresh();
    }

    updateStateWithArticles(actus: Actu[]) {
        let createdAt = 0;
        let id = 0;

        actus.forEach(actu => {
            createdAt = actu.createdAt;
            id = actu.id;
        });

        this.setState({
            // concat() because we can load MORE !
            actus: this.state.actus.concat(actus) as Actu[],
            canLoadMore: actus.length >= this.props.limit,
            latestCreatedAt: createdAt,
            latestId: id,
            loading: false,
            error: false
        });
    }

    buildUrl(): string {
        const params = [
            "nope=" + Math.floor(Date.now() / 1000), // Cache buster.
            "limit=" + this.props.limit,
        ] as string[];
        if (this.state.latestCreatedAt) {
            params.push("from=" + this.state.latestCreatedAt);
        }
        if (this.state.latestId) {
            params.push("after=" + this.state.latestId);
        }
        return this.props.loadUrl + "?" + params.join("&");
    }

    refresh() {
        this.setState({loading: true});
        request(this.buildUrl())
            .then(response => JSON.parse(response.responseText) as Actu[])
            .then(actus => this.updateStateWithArticles(actus))
            .catch(err => {
                this.setState({error: true})
                console.log(err);
            })
        ;
    }

    render() {
        const actus = [];
        for (let actu of this.state.actus) {
            actus.push((
                <div className="col-md-4" key={actu.id}>
                    <article className="clearfix">
                        <div data-nid="{actu.id}" className="media">
                            <div className="media">
                                <img src={actu.imageUri} width="570" height="570"/>
                            </div>
                            <div className="media-body">
                                <h3 className="media-heading">{actu.title}</h3>
                                <div className="block-with-text">{actu.teaser}</div>
                            </div>
                        </div>
                    </article>
                    <p className="text-center">
                        <a className="btn btn-alternate-primary" href={actu.uri}>Lire la suite</a>
                    </p>
                </div>
            ));
        }

        let button;
        if (this.state.error) {
            button = <ActuLink href="{this.props.allUrl}"/>
        } else if (this.state.loading) {
            button = <a role="button" className="btn btn-primary disabled" href="#" onClick={this.onClickNoop}>En cours de chargement</a>;
        } else if (!this.state.canLoadMore) {
            button = <a role="button" className="btn btn-primary disabled" href="#" onClick={this.onClickNoop}>Charger plus d'articles</a>;
        } else {
            button = <a role="button" className="btn btn-primary" href="#" onClick={this.onLoadMoreClick}>Charger plus d'articles</a>;
        }

        if (actus.length) {
            return (
                <div className="row article-list">
                    <div className="col-md-12"><h2>Actualités</h2></div>
                    {actus}
                    <div className="col-md-12 all-link">{button}</div>
                </div>
            );
        }
        return null;
    }
}

document.addEventListener("DOMContentLoaded", event => {
    const list = document.querySelector(`[data-actu="list"]`);
    console.log("Actu: loading...");
    if (list) {
        console.log("Actu: found container...");
        const allUrl = list.getAttribute("data-all-url") as string;
        const loadUrl = list.getAttribute("data-load-url") as string;
        if (loadUrl) {
            ReactDOM.render(<ActuList allUrl={allUrl} loadUrl={loadUrl} limit={3}/>, list);
        } else {
            ReactDOM.render(<ActuLink href="{allUrl}"/>, list);
        }
    }
});
