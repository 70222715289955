
//import { Drupal } from 'drupal';
declare var Drupal: any;

Drupal.behaviors.saurMegaMenu = {
    attach: (context: Element, settings: any) => {
        if (context.querySelectorAll) {
            attachTopLevelBehavior(<HTMLElement>context);
            openMenuEdmc();
        }
    }
};

class Pane {
    opened: boolean = false;
    readonly container: HTMLElement;
    readonly title: HTMLElement;
    constructor(container: HTMLElement, title: HTMLElement) {
        this.container = container;
        this.title = title;
    }
}

function openMenuEdmc() {
    const menuEdmc = document.querySelector('li.water') as HTMLElement;

    if(menuEdmc) {
        if(window.location.hash.substr(1)) {
            if(window.location.hash.substr(1) == 'water') {
              menuEdmc.click();
            }
        }
    }
}

function hideFirstLevelPane(pane: Pane, close: boolean | void = undefined) {
    //pane.container.style.display = "none";
    pane.opened = false;
    pane.container.classList.remove('opened');
    if (close) {
        pane.title.classList.remove("current");
    } else {
        pane.title.classList.remove("current");
    }
}

function showFirstLevelPane(pane: Pane) {
    //pane.container.style.display = "block";
    pane.opened = true;
    pane.container.classList.add('opened');
    pane.title.classList.add("current");
    pane.title.classList.remove("not-current");
}

function hideSecondLevelPane(pane: Pane) {
    pane.opened = false;
    pane.container.style.display = "none";
    pane.title.classList.add("not-hover");
    pane.title.classList.remove("hover");
}

function showSecondLevelPane(pane: Pane) {
    pane.opened = true;
    pane.container.style.display = "block";
    pane.title.classList.add("hover");
    pane.title.classList.remove("not-hover");
}

function isDeviceMobile(): boolean {
    if (window.matchMedia) {
        return window.matchMedia('(max-width: 992px)').matches; // breakpoint for menu in css
    }
    return false;
}

function isInSelect2(element: Element): boolean {
    let parent = element;
    while(true) {
        if (parent.classList.contains("select2-container")) {
            return true;
        }
        if (parent.parentElement) {
            parent = parent.parentElement;
        } else {
            return false;
        }
    }
}

function attachTopLevelBehavior(context: HTMLElement): void {
    let element = <HTMLElement>context.querySelector(".main-navig");
    const panes: Pane[] = [];

    if (!element) {
        return;
    }

    const closeListener = (event: Event) => {
        for (let sibling of panes) {
            hideFirstLevelPane(sibling, true);
        }
    };

    const smartCloseListener = (event: Event) => {
        // Sorry for this line.
        let target: Node | null;
        if (event instanceof FocusEvent) {
            target = <Node>event.relatedTarget;
        } else {
            target = <Node>event.target;
        }
        if (target) {
            // Sad hack, but the select2 popup goes away in the body, so
            // must ensure we're not in the select popup too.
            if (isInSelect2(<Element>target)) {
                return;
            }
            // The HTML markup is very wrong: we have to test all panes
            // one by one to determine if the current focus event is focusing
            // an element in the menu or not.
            if (element.contains(target)) {
                return;
            }
            for (let sibling of panes) {
                if (sibling.container.contains(target)) {
                    return;
                }
            }
        }
        // Reaching here means that there is no menu element containing the
        // new focused element (or there is no new focused element) case
        // in which we can safely close the whole menu.
        for (let sibling of panes) {
            hideFirstLevelPane(sibling, true);
        }
    };

    element.tabIndex = -1; // So the focusout event works as expected

    for (let id of ['search', 'primary', 'water', 'customer']) {

        const title = <HTMLElement>element.querySelector(`.${id}`);
        if (!title) {
            continue;
        }
        const container = <HTMLElement>context.querySelector(`#wrapper-${id}`);
        if (!container) {
            continue;
        }

        const pane = new Pane(container, title);
        panes.push(pane);
        attachSecondLevelBehavior(pane);
        title.addEventListener("click", (event: Event) => {
            // Theme light exception
            if (event.target instanceof HTMLElement && event.target.classList.contains('follow')) {
                return;
            }

            // If title is a link, this click must not follow the link.
            event.stopPropagation();
            event.preventDefault();

            for (let sibling of panes) {
                if (sibling !== pane) {
                    hideFirstLevelPane(sibling);
                }
            }
            showFirstLevelPane(pane);
        });

        const closeButton = <HTMLElement>container.querySelector('.close-this-pane');
        if (closeButton) {
            closeButton.addEventListener("click", closeListener);
        }
    }

    document.addEventListener("mouseup", smartCloseListener);
}

function attachSecondLevelBehavior(parent: Pane): void {
    const panes: Pane[] = [];
    let first = true;

    for (let title of <HTMLElement[]><any>parent.container.querySelectorAll('li.level-1 > a')) {
        if (!title.parentElement) {
            continue;
        }
        const container = <HTMLElement>title.parentElement.querySelector("div.wrapp-sous-rub");
        if (!container) {
            continue;
        }

        const pane = new Pane(container, title.parentElement /* li.level-1 */);
        panes.push(pane);

        if (!isDeviceMobile() && first) { // do not pre-open the first one in mobile
            showSecondLevelPane(pane);
            first = false;
        } else {
            hideSecondLevelPane(pane);
        }

        const openPaneListener = (event: Event) => {
            // If title is a link, this click must not follow the link.
            event.stopPropagation();
            event.preventDefault();

            for (let sibling of panes) {
                if (sibling !== pane) {
                    hideSecondLevelPane(sibling);
                }
            }
            // Allow second level to be closed on mobile.
            // Dev. note: initial version had a const boolean and isDeviceMobile() was only
            // called only once upon very first initialization, but this prevents devs and
            // testers from resizing their browser, so we do dynamically call this every
            // time.
            if (isDeviceMobile() && pane.opened) {
                hideSecondLevelPane(pane);
            } else {
                showSecondLevelPane(pane);
            }
        };

        //title.addEventListener("hover", openPaneListener);
        title.addEventListener("click", openPaneListener);
    }
}
