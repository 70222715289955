// Drupal theme saur_default - www/profiles/theme/saur_default/

// Theme LESS & CSS
import "../common/index";
import "./less/style.less";

// Theme specific JS
import "../dist/slick-1.6.0/slick/slick";
import "../saur_default/theme";

// Site wide features and components
import "../common/form-bundle/index";
import "../common/edmc-bundle/index";
