(function ($) {

  Drupal.behaviors.saurFormHelpers = {
    attach: function (context, settings) {
      $('body', context).once('form-helpers', function () {
        $('[data-component]', context).each(function () {
          var html = $(this).html();
          var $button = $('<a role="button" class="glyphicon glyphicon-question-sign show" tabindex="0"/>');
          var $label = $('[data-helper-id=' + $(this).attr('data-component') + ']').find('.control-label');
          $button.popover({
            html: true,
            trigger: 'focus',
            container: 'body',
            content: html
          }).appendTo($label);
          $button.click(function (e) {
            e.preventDefault();
          })
        });
      });
    }
  };

}(jQuery));
