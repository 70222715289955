window.addEventListener('DOMContentLoaded', (event) => {
var mapOptions;
var map;
var infoBubbleOptions;
var infoBubble;

function initialize() {
	//Map
	mapOptions = {
		zoom: 13,
		center: new google.maps.LatLng(0, 0),
		mapTypeControl: false,
		rotateControl: true,
		mapTypeId: google.maps.MapTypeId.ROADMAP
	}
	map = new google.maps.Map(document.getElementById('intervention-map'), mapOptions);

	//InfoBulle
	infoBubbleOptions = {
		map: map,
		shadowStyle: 0,//1,
		padding: 10,
		borderRadius: 0,//7,
		borderWidth: 0,
		hideCloseButton: true,
		maxHeight: 800,
		minHeight: 200,//120,
		minWidth: 360,
		maxWidth: 360,
		disableAutoPan: false,//center marker
		arrowSize: 10,
		arrowStyle: 0,
		arrowPosition: '50%',
		disableAnimation: false,
		style: {
			top: 50
		}
	}

	infoBubble = new InfoBubble(infoBubbleOptions);
	customCloseButton(infoBubble);

	//Close infoBubble by clicking anywhere on the map
	google.maps.event.addListener(map, 'click', function (e) {
		infoBubble.close();
	});

	//Markers
	setMarkers(map);

	setMapSize(260);
}

function setMarkers(map) {
	var shadow = new google.maps.MarkerImage('shadow.png',
			new google.maps.Size(37, 32),
			new google.maps.Point(0, 15),
			new google.maps.Point(10, 40));
	//no construction, center by city
	var icon_encours = new google.maps.MarkerImage(" data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAzCAYAAAAdD7HCAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAQeSURBVFhHxZlLbExRHMbv1KOVSBoLQlVJJE2QsqgNFjYWgliVhIUWiXo0Qph4v1JshmjDgkSbirBo0iCIphFqMR6pRkqCsCCiJAgiEeM5vu/+zx2dmTP3njMP/SVf/v9zO3PvN+d9bkOOBbHIbHw+NBPpAmgWVAmNg0qgGPQWegbdga7iw73F4WgcuRFGZmKROcMRVkEboSm8ZshjqBlqKwlHf7hXfAg0AyPzEU5Ak90L2fEcaoChLinqyWgGJoYhHIUaIKMaDIDNxVoKw9Qv90oK2ofAyEiEC9A890J+6YRqYOirFP+RZgZG2Bn5hbnuhcJwHVoEQ9+lKCSZgRGG89AyJoa0Q0+gqdASXjCkLRRyVhZvjaqi4xSp6FEP2Rj5AtXhF+5HrFVlU+ricadO5S4JM6iVCQhHpGRMB4x8Y6JiB3MLmvDcsSpPqplDEDuuDWdV9EgtB1EKNUqqzMAdZ9LlzC14hdF6S+UKt4zrVtTi+ROZeDWzARoiqTEX0f9LcaNRnliW61ZwPlvHJISbDEV8A43mhUGiH7VawZrheB5MI2Q86qWaNcNhuU+uGRPUNyog20lzG81cQbJQykb8hMoxlN9JMR3ccwzCa4j9wZR2NhNHkg1dfkaI+rvvCq2hkmbKJDfGdC6xnXPK2EzGOzHApX8p5M66AYyAuG5xtJoQo5k/SPKxX8mVOJspaRkfRNyawYRj1G84OiKSWhGGyiX1pZ9mbiPhTj+Iwxglu1RuDO7PBXinlHyJspn6JA/EdnR4mH6vj2a6JfelB7XyVOVWqO/1SMmXmzRzDco0VF9AB6Dtbil7+H3eh/fTwc15pzuk0a5nEFYwT6EZv2yTynMGz+FRhQfBVFrxnNWsGdIE6SY/niTziW6t4nOPMUlMdnB9CWGxlBLw/PwQspmlM8FnTYd4FBoI99E1TAaaqUJ4ANnu+HKBy0uVNzi8ZmKvf4TQKqX/xinPCEmYUeyGPklacD5AeyUVkszAJfche6RUcHbgeR9V7pJaM+QkdF/SgsEzbVqX0G4d0JmrEe5CpnsRG7htrVZ9NAldzbC5ehGOSynvRHRGiNaMgn3npaR5g+/7EsfZVDKagXuuF+ullBc4cdbjvpxItfjVDA1xET0npZxpwf18dwi+ZhSbIc4JucDjM3d8vgSawa95j5Drys03nZ9VnhGTmiFsKjZZNnQUxeN8WRmIdp7RgblnEgKHpM0LJdbGNNQKmykQ05phc3GYc+2yge98jYwQYzMKvim/J2kg3RjMLSo3wriZPNBc3CBx7fJ7w8C5ZAZqhZOcMbY1w+bizi/oMNdoa4RYm1EchPjPCR0mZrVkZQa/mkebtVDq3vg3tAZ/58psTbY1Q0M3EE5LKQG3kaYdPI2szSi2QJchvslgzOGw5zh/AWymHXT7bbWpAAAAAElFTkSuQmCC");
	var icon_avenir = new google.maps.MarkerImage("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAzCAYAAAD7JnqGAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAR5SURBVFhHzZlbbBVFGMf3FCWGxEsIaSQWfDAqtqVQMGBIePDB8NAnTEwaMbUtN5FySyA+ULCkkQdjjEbTFNOIbb00ItaQ0ECNXF8wFJBSyjWQQAgElShE2kR71v9/vjmHs+3u2Znp6Sm/5N/5vmnP7v98szs7s014lpTUtCZAGcJF0HxoBvQM9DhE7kI3oAvQMWi/53u9fTurEJpjbKy0tu0JNMugFdCL7LPgPPQF1NL3ZdU91RNDrLGS2rZH8UerEW6BJqtOd/6AGqEmGPxP9USQ1RiqxMp8A81VHbnjOLQE5i5JOpJIYzBVgeZbiEM4FvwNVcLcPkmDFOg2AEy9ieYnaKxMkSehPTjXG5IGGVExXSmaekR1mHEZYnXJEug5CY34F6pA5X6WVAgYg6kX0HD8bSvF60UZ09XmdWnDX9BcHOOKpBlDibuPFeIBbU39Cf0ooYIx+2x4CmovqWlL+0kHekp4WTIr2vFNB3Xs6bhdMisWJBJqnlQoYyg/Z23OUy606DaTsD4TGuBlEgN1jSHZgOZjxpacgF6TcAS8mF3mv1WoenNBcbWqOh8zLvDEdyLkOikv548EqsUH8mkmDxHP09gmBB9KbsVJ6KyEkZRAcyS0YjWN7UbwuuRWlONa+E3HoeDYs9GckswKNW+8JLEVx4eSBVlNEX8oyb/hhG1LMY1Nk9iKlnNfvaXDaM62VrNxmTqKOJS+TkxJQpWQ0YIP8EnyHZSezA0YoDGeKHL5M074/Bbpx8lDxCArdh1BkeSxcIPRJKE170Kme4XrNHYEwULJY1mDKeJzHVuB89Sh+UyyWA5zKHskjmUA+lpCJ/hZHsOEHhrrljiWXagWF3RO6M/ukiyWbhr7Bbqt0nD2Qg2Qy2NrODzGNojHjOIWdCC17OFer55xCK/g2/6q45yA83EHz116GNtwvobUpPcpFDVhTtRtLok6Jrd06gZJT6z4Fu+j4ZAN5yZ0TcKcMR2aKmGAelTrAwaZxrik7YeeVR35hzukUhhTd25qKHnX3EezXrJxgXNkejpJGyNJX210M7di+aKj78q0Lh0rAsb65R0WZ2iu2fPF79Ba79CrkmkCxgjKyYt9nWR5oQ7npLkAI4wR3/P5+OCwjjUdfsL7XscBItdhuEsL0ZyB2I4FHJmZqFbo64TQihF8gI+pdyTLOVw110aZIpHGCD7YiWanZDmlGccOfWGXIqsxDee2qxLmhIsQ97JZiTWGb8bX429DQ6pjdPCFcBWO+Y+k0ZhUjOaOovlIslHRiGMZrVSMjGm2QrGb3CzQ0HYJ47HatmEK4bsILsUfUx3mcOjmoFq8voywqRiHlC9RNktmxUYbU8TKmOYT6KCERnRh1tqhY2OshjIFhpQLvV6I7+qzwX/RlKFanOWtcKkYh5Qr2jWSZWWliyniZIz4SfVm+gfJQmnzkwnntZ3TUKbAkE5Bwwf906rjAazoLFTLeR/qXDGCE/MaWgplvspivGw0psgE3Tpz+1TnpcLyxVwlzIO4b3gPpjr4O3c873/9kkCqetZpVAAAAABJRU5ErkJggg==");
	var bounds = new google.maps.LatLngBounds();

	//only one construction

	var onMarkerClick = function () {
		var marker = this;
		console.debug(marker.desc);
		infoBubble.setContent(marker.desc);
		if (document.getElementById('infoBubble')) {
			addClickListenerToStopPropagation(document.getElementById('infoBubble'));
			infoBubble.setMinHeight(document.getElementById('infoBubble').offsetHeight);
		}
		infoBubble.open(map, marker);
	};

    const codinsee = window.insee;

    if (window.interventions) {
        var interventions = window.interventions.result;
        var positionCommune;
        var geocoder = new google.maps.Geocoder();
        var url = 'https://api-adresse.data.gouv.fr/search/?'


        $.ajax({
            crossDomain: true,
            dataType: "json",
            url: url + 'q=mairie&citycode=' + codinsee,
            success: function (data) {

                var lat = data.features[0].geometry.coordinates[1];
                var lng = data.features[0].geometry.coordinates[0];
                positionCommune = new google.maps.LatLng(lat, lng);

                interventions.forEach(function (intervention) {

                    if (intervention.statut == 'En cours'){
                        var icon = icon_encours;
                    }
                    else if (intervention.statut == 'A venir'){
                        var icon = icon_avenir;
                    }

                    if ((intervention.coordonneeX >= -180 && intervention.coordonneeX <= 180) && (intervention.coordonneeY >= -180 && intervention.coordonneeY <= 180) && (intervention.coordonneeX != 0 && intervention.coordonneeY != 0)) {
                        console.log("coordonnee " + intervention.adresse);
                        var lat = intervention.coordonneeY;
                        var lng = intervention.coordonneeX;
                        var position = new google.maps.LatLng(lat, lng);

                        var marker = new google.maps.Marker({
                            position: position,
                            map: map,
                            icon: icon,
                            shadow: shadow
                        });

                        var content = InfoBubbleContent(
                            intervention.typeIntervention,
                            intervention.adresse,
                            intervention.dateHeureDebut,
                            intervention.dateHeureFin,
                            intervention.statut,
                            intervention.commentaire
                        );

                        marker.desc = content;

                        google.maps.event.addListener(marker, 'click', onMarkerClick);
                        map.setZoom(12);
                        map.setCenter(position);

                    }else {
                        $.ajax({
                            crossDomain: true,
                            dataType: "json",
                            url: url + 'q=' + intervention.adresse + '&citycode=' + codinsee,
                            success: function (data) {
                                if (data.features.length != 0){
                                    console.log("geocode adresse " + intervention.adresse);
                                    var lat = data.features[0].geometry.coordinates[1];
                                    var lng = data.features[0].geometry.coordinates[0];
                                    var position = new google.maps.LatLng(lat, lng);
                                    var marker = new google.maps.Marker({
                                        position: position,
                                        map: map,
                                        icon: icon,
                                        shadow: shadow
                                    });

                                    var content = InfoBubbleContent(
                                        intervention.typeIntervention,
                                        intervention.adresse,
                                        intervention.dateHeureDebut,
                                        intervention.dateHeureFin,
                                        intervention.statut,
                                        intervention.commentaire
                                    );

                                    marker.desc = content;

                                    google.maps.event.addListener(marker, 'click', onMarkerClick);
                                    map.setZoom(12);
                                    map.setCenter(position);

                                }else{
                                    console.log("geocode insee " + intervention.adresse);
                                    var marker = new google.maps.Marker({
                                        position: positionCommune,
                                        map: map,
                                        icon: icon,
                                        shadow: shadow
                                    });

                                    var content = InfoBubbleContent(
                                        intervention.typeIntervention,
                                        intervention.adresse,
                                        intervention.dateHeureDebut,
                                        intervention.dateHeureFin,
                                        intervention.statut,
                                        intervention.commentaire
                                    );

                                    marker.desc = content;

                                    google.maps.event.addListener(marker, 'click', onMarkerClick);
                                    map.setZoom(12);
                                    map.setCenter(positionCommune);

                                }
                            }
                        });
                    }
                });
            }
        });
    }

	//only one ongoing constuction

	//only one coming constuction
	//more than 1 construction, use OverlappingMarkerSpiderfier

}



function setMapSize(marge) {
	if (document.documentElement.clientWidth < 1100) {
		var mapSize = eval(document.documentElement.clientHeight - marge);
		if (document.documentElement.clientWidth < 375) {
			mapSize *= 0.85;
		}
		$("#map").css("height", mapSize);
	}
}

function InfoBubbleContent(objet, adresse, debut, fin, type, comment) {
	return '<div class="infoBubble">' +
            '<p><strong>Type d\'intervention&nbsp;: </strong><br>' + objet + '</p>' +
            '<p>' +
                '<strong>Statut&nbsp;:</strong> ' + type + '<br>' +
                '<strong>Adresse&nbsp;:</strong> ' + adresse + '<br>' +
                '<strong>D&eacute;but&nbsp;:</strong> ' + debut + '<br>' +
                '<strong>Fin&nbsp;:</strong> ' + fin + '<br>' +
            '</p>' +
            '<p>' + comment + '</p>';
	// return '<div id="infoBubble" class="infoBubble" style="width : auto;">' +
	// 		'<div><label class="titleInter">Type d\'intervention : </label><br><label class="contentInter">' + objet + '</label></div>' +
	// 		'<br><div class="infobulle_content">' +
	// 		'<div><label class="titleContent">Statut : </label><label class="contentInter">' + type + '</label></div>' +
	// 		'<div><label class="titleContent">Adresse : </label><label class="contentInter">' + adresse + '</label></div>' +
	// 		'<div><label class="titleContent">D&eacute;but : </label><label class="contentInter">' + debut + '</label></div>' +
	// 		'<div><label class="titleContent">Fin : </label><label class="contentInter">' + fin + '</label></div>' +
	// 		'<br>' +
	// 		'<div><label class="BlocComment">'+ comment + '</div>' +
	// 		'</div>' +
	// 		'</div>';
}

function customCloseButton(infoBubble) {
	var closeImg = document.createElement("div");
	closeImg.style.position = "absolute";
	closeImg.style.width = "12px";//"10px";
	closeImg.style.height = "12px";//"10px";
	closeImg.style.border = "none";
	closeImg.style.zIndex = 10000;
	closeImg.style.cursor = "pointer";
	closeImg.style.display = "block";
	closeImg.className = "close";
	closeImg.style.background = "no-repeat  url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill:%23799eaf;%7D%3C/style%3E%3C/defs%3E%3Cpath class='a' d='M7.792,102l4.04-4.04a.6.6,0,0,0,0-.847l-.939-.939a.6.6,0,0,0-.847,0l-4.039,4.04-4.04-4.04a.6.6,0,0,0-.847,0l-.938.938a.6.6,0,0,0,0,.847L4.222,102l-4.04,4.04a.6.6,0,0,0,0,.847l.939.939a.6.6,0,0,0,.847,0l4.039-4.04,4.04,4.04a.6.6,0,0,0,.847,0l.939-.939a.6.6,0,0,0,0-.847Z' transform='translate(-0.007 -95.997)'/%3E%3C/svg%3E\")";
	infoBubble.bubble_.appendChild(closeImg);
	positionCloseButton(infoBubble, closeImg);

	google.maps.event.addDomListener(closeImg, 'click', function () {
		infoBubble.close();
	});
}

function positionCloseButton(infoBubble, closeImg) {
	var br = infoBubble.getBorderRadius_();
	var bw = infoBubble.getBorderWidth_();

	var right = 12;
	var top = 12;

	if (infoBubble.tabs_.length && infoBubble.tabHeight_) {
		top += infoBubble.tabHeight_;
	}

	top += bw;
	right += bw;

	var c = infoBubble.contentContainer_;
	if (c && c.clientHeight < c.scrollHeight) {
		right += 15;
	}

	closeImg.style['right'] = infoBubble.px(right);
	closeImg.style['top'] = infoBubble.px(top);
}


function codeAddress(address) {
    geocoder.geocode( { 'address': address}, function(results, status) {
		if (status == 'OK') {
			map.setCenter(results[0].geometry.location);
			var marker = new google.maps.Marker({
				map: map,
				position: results[0].geometry.location
			});
		} else {
			console.error('Geocode was not successful for the following reason: ' + status);
		}
    });
}

function addClickListenerToStopPropagation(elem) {
	google.maps.event.addDomListener(elem, 'click',
			function (e) {
				if (e.stopPropagation) {
					e.stopPropagation();
				} else {
					e.cancelBubble = true;
				}
				// Next line is for IE
				e.returnValue = false;
			}
	);
}

google.maps.event.addDomListener(window, 'load', initialize);


function InfoBubble(b) {
    this.tabs_ = [];
    this.activeTab_ = null;
    this.baseZIndex_ = 100;
    this.isOpen_ = false;
    var a = b || {};
    if (a.backgroundColor == undefined) {
        a.backgroundColor = this.BACKGROUND_COLOR_
    }
    if (a.borderColor == undefined) {
        a.borderColor = this.BORDER_COLOR_
    }
    if (a.borderRadius == undefined) {
        a.borderRadius = this.BORDER_RADIUS_
    }
    if (a.borderWidth == undefined) {
        a.borderWidth = this.BORDER_WIDTH_
    }
    if (a.padding == undefined) {
        a.padding = this.PADDING_
    }
    if (a.arrowPosition == undefined) {
        a.arrowPosition = this.ARROW_POSITION_
    }
    if (a.disableAutoPan == undefined) {
        a.disableAutoPan = false
    }
    if (a.disableAnimation == undefined) {
        a.disableAnimation = false
    }
    if (a.minWidth == undefined) {
        a.minWidth = this.MIN_WIDTH_
    }
    if (a.shadowStyle == undefined) {
        a.shadowStyle = this.SHADOW_STYLE_
    }
    if (a.arrowSize == undefined) {
        a.arrowSize = this.ARROW_SIZE_
    }
    if (a.arrowStyle == undefined) {
        a.arrowStyle = this.ARROW_STYLE_
    }
    this.buildDom_();
    this.setValues(a)
}
InfoBubble.prototype = new google.maps.OverlayView();
window.InfoBubble = InfoBubble;
InfoBubble.prototype.ARROW_SIZE_ = 15;
InfoBubble.prototype.ARROW_STYLE_ = 0;
InfoBubble.prototype.SHADOW_STYLE_ = 1;
InfoBubble.prototype.MIN_WIDTH_ = 50;
InfoBubble.prototype.ARROW_POSITION_ = 50;
InfoBubble.prototype.PADDING_ = 10;
InfoBubble.prototype.BORDER_WIDTH_ = 1;
InfoBubble.prototype.BORDER_COLOR_ = "#ccc";
InfoBubble.prototype.BORDER_RADIUS_ = 10;
InfoBubble.prototype.BACKGROUND_COLOR_ = "#fff";
InfoBubble.prototype.buildDom_ = function() {
    var l = this.bubble_ = document.createElement("DIV");
    l.style.position = "absolute";
    l.style.zIndex = this.baseZIndex_;
    var c = this.tabsContainer_ = document.createElement("DIV");
    c.style.position = "relative";
    var n = this.close_ = document.createElement("IMG");
    n.style.position = "absolute";
    n.style.width = this.px(12);
    n.style.height = this.px(12);
    n.style.border = 0;
    n.style.zIndex = this.baseZIndex_ + 1;
    n.style.cursor = "pointer";
    var b = document.location.protocol;
    // n.src = b + basePath + "iw_close.gif";
    var i = this;
    google.maps.event.addDomListener(n, "click", function() {
        i.close()
    });
    var k = this.contentContainer_ = document.createElement("DIV");
    k.style.overflowX = "auto";
    k.style.overflowY = "auto";
    k.style.cursor = "default";
    k.style.clear = "both";
    k.style.position = "relative";
    var j = this.content_ = document.createElement("DIV");
    k.appendChild(j);
    var m = this.arrow_ = document.createElement("DIV");
    m.style.position = "relative";
    var d = this.arrowOuter_ = document.createElement("DIV");
    var e = this.arrowInner_ = document.createElement("DIV");
    var g = this.getArrowSize_();
    d.style.position = e.style.position = "absolute";
    d.style.left = e.style.left = "50%";
    d.style.height = e.style.height = "0";
    d.style.width = e.style.width = "0";
    d.style.marginLeft = this.px(-g);
    d.style.borderWidth = this.px(g);
    d.style.borderBottomWidth = 0;
    var f = this.bubbleShadow_ = document.createElement("DIV");
    f.style.position = "absolute";
    l.style.display = f.style.display = "none";
    l.appendChild(this.tabsContainer_);
    l.appendChild(n);
    l.appendChild(k);
    m.appendChild(d);
    m.appendChild(e);
    l.appendChild(m);
    var a = document.createElement("style");
    a.setAttribute("type", "text/css");
    this.animationName_ = "_ibani_" + Math.round(Math.random() * 10000);
    var h = "." + this.animationName_ + "{-webkit-animation-name:" + this.animationName_ + ";-webkit-animation-duration:0.5s;-webkit-animation-iteration-count:1;}@-webkit-keyframes " + this.animationName_ + " {from {-webkit-transform: scale(0)}50% {-webkit-transform: scale(1.2)}90% {-webkit-transform: scale(0.95)}to {-webkit-transform: scale(1)}}";
    a.textContent = h;
    document.getElementsByTagName("head")[0].appendChild(a)
};
InfoBubble.prototype.setBackgroundClassName = function(a) {
    this.set("backgroundClassName", a)
};
InfoBubble.prototype.setBackgroundClassName = InfoBubble.prototype.setBackgroundClassName;
InfoBubble.prototype.backgroundClassName_changed = function() {
    this.content_.className = this.get("backgroundClassName")
};
InfoBubble.prototype.backgroundClassName_changed = InfoBubble.prototype.backgroundClassName_changed;
InfoBubble.prototype.setTabClassName = function(a) {
    this.set("tabClassName", a)
};
InfoBubble.prototype.setTabClassName = InfoBubble.prototype.setTabClassName;
InfoBubble.prototype.tabClassName_changed = function() {
    this.updateTabStyles_()
};
InfoBubble.prototype.tabClassName_changed = InfoBubble.prototype.tabClassName_changed;
InfoBubble.prototype.getArrowStyle_ = function() {
    return parseInt(this.get("arrowStyle"), 10) || 0
};
InfoBubble.prototype.setArrowStyle = function(a) {
    this.set("arrowStyle", a)
};
InfoBubble.prototype.setArrowStyle = InfoBubble.prototype.setArrowStyle;
InfoBubble.prototype.arrowStyle_changed = function() {
    this.arrowSize_changed()
};
InfoBubble.prototype.arrowStyle_changed = InfoBubble.prototype.arrowStyle_changed;
InfoBubble.prototype.getArrowSize_ = function() {
    return parseInt(this.get("arrowSize"), 10) || 0
};
InfoBubble.prototype.setArrowSize = function(a) {
    this.set("arrowSize", a)
};
InfoBubble.prototype.setArrowSize = InfoBubble.prototype.setArrowSize;
InfoBubble.prototype.arrowSize_changed = function() {
    this.borderWidth_changed()
};
InfoBubble.prototype.arrowSize_changed = InfoBubble.prototype.arrowSize_changed;
InfoBubble.prototype.setArrowPosition = function(a) {
    this.set("arrowPosition", a)
};
InfoBubble.prototype.setArrowPosition = InfoBubble.prototype.setArrowPosition;
InfoBubble.prototype.getArrowPosition_ = function() {
    return parseInt(this.get("arrowPosition"), 10) || 0
};
InfoBubble.prototype.arrowPosition_changed = function() {
    var a = this.getArrowPosition_();
    this.arrowOuter_.style.left = this.arrowInner_.style.left = a + "%";
    this.redraw_()
};
InfoBubble.prototype.arrowPosition_changed = InfoBubble.prototype.arrowPosition_changed;
InfoBubble.prototype.setZIndex = function(a) {
    this.set("zIndex", a)
};
InfoBubble.prototype.setZIndex = InfoBubble.prototype.setZIndex;
InfoBubble.prototype.getZIndex = function() {
    return parseInt(this.get("zIndex"), 10) || this.baseZIndex_
};
InfoBubble.prototype.zIndex_changed = function() {
    var a = this.getZIndex_();
    this.bubble_.style.zIndex = this.baseZIndex_ = a;
    this.close_.style.zIndex = zIndex_ + 1
};
InfoBubble.prototype.zIndex_changed = InfoBubble.prototype.zIndex_changed;
InfoBubble.prototype.setShadowStyle = function(a) {
    this.set("shadowStyle", a)
};
InfoBubble.prototype.setShadowStyle = InfoBubble.prototype.setShadowStyle;
InfoBubble.prototype.getShadowStyle_ = function() {
    return parseInt(this.get("shadowStyle"), 10) || 0
};
InfoBubble.prototype.shadowStyle_changed = function() {
    var a = this.getShadowStyle_();
    var c = "";
    var d = "";
    var b = "";
    switch (a) {
        case 0:
            c = "none";
            break;
        case 1:
            d = "40px 15px 10px rgba(33,33,33,0.3)";
            b = "transparent";
            break;
        case 2:
            d = "0 0 2px rgba(33,33,33,0.3)";
            b = "rgba(33,33,33,0.35)";
            break
    }
    this.bubbleShadow_.style.boxShadow = this.bubbleShadow_.style.webkitBoxShadow = this.bubbleShadow_.style.MozBoxShadow = d;
    this.bubbleShadow_.style.backgroundColor = b;
    this.bubbleShadow_.style.display = c;
    this.draw()
};
InfoBubble.prototype.shadowStyle_changed = InfoBubble.prototype.shadowStyle_changed;
InfoBubble.prototype.showCloseButton = function() {
    this.set("hideCloseButton", false)
};
InfoBubble.prototype.showCloseButton = InfoBubble.prototype.showCloseButton;
InfoBubble.prototype.hideCloseButton = function() {
    this.set("hideCloseButton", true)
};
InfoBubble.prototype.hideCloseButton = InfoBubble.prototype.hideCloseButton;
InfoBubble.prototype.hideCloseButton_changed = function() {
    this.close_.style.display = this.get("hideCloseButton") ? "none" : ""
};
InfoBubble.prototype.hideCloseButton_changed = InfoBubble.prototype.hideCloseButton_changed;
InfoBubble.prototype.setBackgroundColor = function(a) {
    if (a) {
        this.set("backgroundColor", a)
    }
};
InfoBubble.prototype.setBackgroundColor = InfoBubble.prototype.setBackgroundColor;
InfoBubble.prototype.backgroundColor_changed = function() {
    var a = this.get("backgroundColor");
    this.contentContainer_.style.backgroundColor = a;
    this.arrowInner_.style.borderColor = a + " transparent transparent";
    this.updateTabStyles_()
};
InfoBubble.prototype.backgroundColor_changed = InfoBubble.prototype.backgroundColor_changed;
InfoBubble.prototype.setBorderColor = function(a) {
    if (a) {
        this.set("borderColor", a)
    }
};
InfoBubble.prototype.setBorderColor = InfoBubble.prototype.setBorderColor;
InfoBubble.prototype.borderColor_changed = function() {
    var c = this.get("borderColor");
    var b = this.contentContainer_;
    var a = this.arrowOuter_;
    b.style.borderColor = c;
    a.style.borderColor = c + " transparent transparent";
    b.style.borderStyle = a.style.borderStyle = this.arrowInner_.style.borderStyle = "solid";
    this.updateTabStyles_()
};
InfoBubble.prototype.borderColor_changed = InfoBubble.prototype.borderColor_changed;
InfoBubble.prototype.setBorderRadius = function(a) {
    this.set("borderRadius", a)
};
InfoBubble.prototype.setBorderRadius = InfoBubble.prototype.setBorderRadius;
InfoBubble.prototype.getBorderRadius_ = function() {
    return parseInt(this.get("borderRadius"), 10) || 0
};
InfoBubble.prototype.borderRadius_changed = function() {
    var b = this.getBorderRadius_();
    var a = this.getBorderWidth_();
    this.contentContainer_.style.borderRadius = this.contentContainer_.style.MozBorderRadius = this.contentContainer_.style.webkitBorderRadius = this.bubbleShadow_.style.borderRadius = this.bubbleShadow_.style.MozBorderRadius = this.bubbleShadow_.style.webkitBorderRadius = this.px(b);
    this.tabsContainer_.style.paddingLeft = this.tabsContainer_.style.paddingRight = this.px(b + a);
    this.redraw_()
};
InfoBubble.prototype.borderRadius_changed = InfoBubble.prototype.borderRadius_changed;
InfoBubble.prototype.getBorderWidth_ = function() {
    return parseInt(this.get("borderWidth"), 10) || 0
};
InfoBubble.prototype.setBorderWidth = function(a) {
    this.set("borderWidth", a)
};
InfoBubble.prototype.setBorderWidth = InfoBubble.prototype.setBorderWidth;
InfoBubble.prototype.borderWidth_changed = function() {
    var a = this.getBorderWidth_();
    this.contentContainer_.style.borderWidth = this.px(a);
    this.tabsContainer_.style.top = this.px(a);
    this.updateArrowStyle_();
    this.updateTabStyles_();
    this.borderRadius_changed();
    this.redraw_()
};
InfoBubble.prototype.borderWidth_changed = InfoBubble.prototype.borderWidth_changed;
InfoBubble.prototype.updateArrowStyle_ = function() {
    var c = this.getBorderWidth_();
    var e = this.getArrowSize_();
    var b = this.getArrowStyle_();
    var f = this.px(e);
    var g = this.px(e - c);
    var d = this.arrowOuter_;
    var a = this.arrowInner_;
    this.arrow_.style.marginTop = this.px(-c);
    d.style.borderTopWidth = f;
    a.style.borderTopWidth = g;
    if (b == 0 || b == 1) {
        d.style.borderLeftWidth = f;
        a.style.borderLeftWidth = g
    } else {
        d.style.borderLeftWidth = a.style.borderLeftWidth = 0
    }
    if (b == 0 || b == 2) {
        d.style.borderRightWidth = f;
        a.style.borderRightWidth = g
    } else {
        d.style.borderRightWidth = a.style.borderRightWidth = 0
    }
    if (b < 2) {
        d.style.marginLeft = this.px(-(e));
        a.style.marginLeft = this.px(-(e - c))
    } else {
        d.style.marginLeft = a.style.marginLeft = 0
    }
    if (c == 0) {
        d.style.display = "none"
    } else {
        d.style.display = ""
    }
};
InfoBubble.prototype.setPadding = function(a) {
    this.set("padding", a)
};
InfoBubble.prototype.setPadding = InfoBubble.prototype.setPadding;
InfoBubble.prototype.getPadding_ = function() {
    return parseInt(this.get("padding"), 10) || 0
};
InfoBubble.prototype.padding_changed = function() {
    var a = this.getPadding_();
    this.contentContainer_.style.padding = this.px(a);
    this.updateTabStyles_();
    this.redraw_()
};
InfoBubble.prototype.padding_changed = InfoBubble.prototype.padding_changed;
InfoBubble.prototype.px = function(a) {
    if (a) {
        return a + "px"
    }
    return a
};
InfoBubble.prototype.addEvents_ = function() {
    var c = ["mousedown", "mousemove", "mouseover", "mouseout", "mouseup", "mousewheel", "DOMMouseScroll", "touchstart", "touchend", "touchmove", "dblclick", "contextmenu"];
    var a = this.bubble_;
    this.listeners_ = [];
    for (var b = 0, d; d = c[b]; b++) {
        this.listeners_.push(google.maps.event.addDomListener(a, d, function(f) {
            f.cancelBubble = true;
            if (f.stopPropagation) {
                f.stopPropagation()
            }
        }))
    }
};
InfoBubble.prototype.onAdd = function() {
    if (!this.bubble_) {
        this.buildDom_()
    }
    this.addEvents_();
    var a = this.getPanes();
    if (a) {
        a.floatPane.appendChild(this.bubble_);
        a.floatShadow.appendChild(this.bubbleShadow_)
    }
};
InfoBubble.prototype.onAdd = InfoBubble.prototype.onAdd;
InfoBubble.prototype.draw = function() {
    var g = this.getProjection();
    if (!g) {
        return
    }
    var b = (this.get("position"));
    if (!b) {
        this.close();
        return
    }
    var l = 0;
    if (this.activeTab_) {
        l = this.activeTab_.offsetHeight
    }
    var j = this.getAnchorHeight_();
    var f = this.getArrowSize_();
    var e = this.getArrowPosition_();
    e = e / 100;
    var i = g.fromLatLngToDivPixel(b);
    var a = this.contentContainer_.offsetWidth;
    var k = this.bubble_.offsetHeight;
    if (!a) {
        return
    }
    var h = i.y - (k + f);
    if (j) {
        h -= j
    }
    var c = i.x - (a * e);
    this.bubble_.style.top = this.px(h - 10);
    this.bubble_.style.left = this.px(c);
    var d = parseInt(this.get("shadowStyle"), 10);
    switch (d) {
        case 1:
            this.bubbleShadow_.style.top = this.px(h + l - 1);
            this.bubbleShadow_.style.left = this.px(c);
            this.bubbleShadow_.style.width = this.px(a);
            this.bubbleShadow_.style.height = this.px(this.contentContainer_.offsetHeight - f);
            break;
        case 2:
            a = a * 0.8;
            if (j) {
                this.bubbleShadow_.style.top = this.px(i.y)
            } else {
                this.bubbleShadow_.style.top = this.px(i.y + f)
            }
            this.bubbleShadow_.style.left = this.px(i.x - a * e);
            this.bubbleShadow_.style.width = this.px(a);
            this.bubbleShadow_.style.height = this.px(2);
            break
    }
};
InfoBubble.prototype.draw = InfoBubble.prototype.draw;
InfoBubble.prototype.onRemove = function() {
    if (this.bubble_ && this.bubble_.parentNode) {
        this.bubble_.parentNode.removeChild(this.bubble_)
    }
    if (this.bubbleShadow_ && this.bubbleShadow_.parentNode) {
        this.bubbleShadow_.parentNode.removeChild(this.bubbleShadow_)
    }
    for (var a = 0, b; b = this.listeners_[a]; a++) {
        google.maps.event.removeListener(b)
    }
};
InfoBubble.prototype.onRemove = InfoBubble.prototype.onRemove;
InfoBubble.prototype.isOpen = function() {
    return this.isOpen_
};
InfoBubble.prototype.isOpen = InfoBubble.prototype.isOpen;
InfoBubble.prototype.close = function() {
    if (this.bubble_) {
        this.bubble_.style.display = "none";
        this.bubble_.className = this.bubble_.className.replace(this.animationName_, "")
    }
    if (this.bubbleShadow_) {
        this.bubbleShadow_.style.display = "none";
        this.bubbleShadow_.className = this.bubbleShadow_.className.replace(this.animationName_, "")
    }
    this.isOpen_ = false
};
InfoBubble.prototype.close = InfoBubble.prototype.close;
InfoBubble.prototype.open = function(d, a) {
    if (d) {
        this.setMap(map)
    }
    if (a) {
        this.set("anchor", a);
        this.bindTo("position", a)
    }
    this.bubble_.style.display = this.bubbleShadow_.style.display = "";
    var c = !!!this.get("disableAnimation");
    if (c) {
        this.bubble_.className += " " + this.animationName_;
        this.bubbleShadow_.className += " " + this.animationName_
    }
    this.redraw_();
    this.isOpen_ = true;
    var e = !!!this.get("disableAutoPan");
    if (e) {
        var b = this;
        window.setTimeout(function() {
            b.panToView()
        }, 200)
    }
};
InfoBubble.prototype.open = InfoBubble.prototype.open;
InfoBubble.prototype.setPosition = function(a) {
    if (a) {
        this.set("position", a)
    }
};
InfoBubble.prototype.setPosition = InfoBubble.prototype.setPosition;
InfoBubble.prototype.getPosition = function() {
    return (this.get("position"))
};
InfoBubble.prototype.getPosition = InfoBubble.prototype.getPosition;
InfoBubble.prototype.position_changed = function() {
    this.draw()
};
InfoBubble.prototype.position_changed = InfoBubble.prototype.position_changed;
InfoBubble.prototype.panToView = function() {
    var g = this.getProjection();
    if (!g) {
        return
    }
    if (!this.bubble_) {
        return
    }
    var j = this.getAnchorHeight_();
    var l = this.bubble_.offsetHeight + j;
    var a = this.get("map");
    var k = a.getDiv();
    var h = k.offsetHeight;
    var d = this.getPosition();
    var c = g.fromLatLngToContainerPixel(a.getCenter());
    var i = g.fromLatLngToContainerPixel(d);
    var f = c.y - l;
    var m = h - c.y;
    var b = f < 0;
    var e = 0;
    if (b) {
        f *= -1;
        e = (f + m) / 2
    }
    i.y -= e;
    d = g.fromContainerPixelToLatLng(i);
    if (a.getCenter() != d) {
        a.panTo(d)
    }
};
InfoBubble.prototype.panToView = InfoBubble.prototype.panToView;
InfoBubble.prototype.htmlToDocumentFragment_ = function(c) {
    c = c.replace(/^\s*([\S\s]*)\b\s*$/, "$1");
    var b = document.createElement("DIV");
    b.innerHTML = c;
    if (b.childNodes.length == 1) {
        return (b.removeChild(b.firstChild))
    } else {
        var a = document.createDocumentFragment();
        while (b.firstChild) {
            a.appendChild(b.firstChild)
        }
        return a
    }
};
InfoBubble.prototype.removeChildren_ = function(a) {
    if (!a) {
        return
    }
    var b;
    while (b = a.firstChild) {
        a.removeChild(b)
    }
};
InfoBubble.prototype.setContent = function(a) {
    this.set("content", a)
};
InfoBubble.prototype.setContent = InfoBubble.prototype.setContent;
InfoBubble.prototype.getContent = function() {
    return (this.get("content"))
};
InfoBubble.prototype.getContent = InfoBubble.prototype.getContent;
InfoBubble.prototype.content_changed = function() {
    if (!this.content_) {
        return
    }
    this.removeChildren_(this.content_);
    var d = this.getContent();
    if (d) {
        if (typeof d == "string") {
            d = this.htmlToDocumentFragment_(d)
        }
        this.content_.appendChild(d);
        var c = this;
        var a = this.content_.getElementsByTagName("IMG");
        for (var b = 0, e; e = a[b]; b++) {
            google.maps.event.addDomListener(e, "load", function() {
                c.imageLoaded_()
            })
        }
        google.maps.event.trigger(this, "domready")
    }
    this.redraw_()
};
InfoBubble.prototype.content_changed = InfoBubble.prototype.content_changed;
InfoBubble.prototype.imageLoaded_ = function() {
    var a = !!!this.get("disableAutoPan");
    this.redraw_();
    if (a && (this.tabs_.length == 0 || this.activeTab_.index == 0)) {
        this.panToView()
    }
};
InfoBubble.prototype.updateTabStyles_ = function() {
    if (this.tabs_ && this.tabs_.length) {
        for (var b = 0, c; c = this.tabs_[b]; b++) {
            this.setTabStyle_(c.tab)
        }
        this.activeTab_.style.zIndex = this.baseZIndex_;
        var a = this.getBorderWidth_();
        var d = this.getPadding_() / 2;
        this.activeTab_.style.borderBottomWidth = 0;
        this.activeTab_.style.paddingBottom = this.px(d + a)
    }
};
InfoBubble.prototype.setTabStyle_ = function(d) {
    var k = this.get("backgroundColor");
    var f = this.get("borderColor");
    var e = this.getBorderRadius_();
    var a = this.getBorderWidth_();
    var j = this.getPadding_();
    var g = this.px(-(Math.max(j, e)));
    var c = this.px(e);
    var h = this.baseZIndex_;
    if (d.index) {
        h -= d.index
    }
    var l = {
        cssFloat: "left",
        position: "relative",
        cursor: "pointer",
        backgroundColor: k,
        border: this.px(a) + " solid " + f,
        padding: this.px(j / 2) + " " + this.px(j),
        marginRight: g,
        whiteSpace: "nowrap",
        borderRadiusTopLeft: c,
        MozBorderRadiusTopleft: c,
        webkitBorderTopLeftRadius: c,
        borderRadiusTopRight: c,
        MozBorderRadiusTopright: c,
        webkitBorderTopRightRadius: c,
        zIndex: h
    };
    for (var b in l) {
        d.style[b] = l[b]
    }
    var i = this.get("tabClassName");
    if (i != undefined) {
        d.className += " " + i
    }
};
InfoBubble.prototype.addTabActions_ = function(a) {
    var b = this;
    a.listener_ = google.maps.event.addDomListener(a, "click", function() {
        b.setTabActive_(this)
    })
};
InfoBubble.prototype.setTabActive = function(a) {
    var b = this.tabs_[a - 1];
    if (b) {
        this.setTabActive_(b.tab)
    }
};
InfoBubble.prototype.setTabActive = InfoBubble.prototype.setTabActive;
InfoBubble.prototype.setTabActive_ = function(c) {
    if (!c) {
        this.setContent("");
        return
    }
    var d = this.getPadding_() / 2;
    var b = this.getBorderWidth_();
    if (this.activeTab_) {
        var a = this.activeTab_;
        a.style.zIndex = this.baseZIndex_ - a.index;
        a.style.paddingBottom = this.px(d);
        a.style.borderBottomWidth = this.px(b)
    }
    c.style.zIndex = this.baseZIndex_;
    c.style.borderBottomWidth = 0;
    c.style.paddingBottom = this.px(d + b);
    this.setContent(this.tabs_[c.index].content);
    this.activeTab_ = c;
    this.redraw_()
};
InfoBubble.prototype.setMaxWidth = function(a) {
    this.set("maxWidth", a)
};
InfoBubble.prototype.setMaxWidth = InfoBubble.prototype.setMaxWidth;
InfoBubble.prototype.maxWidth_changed = function() {
    this.redraw_()
};
InfoBubble.prototype.maxWidth_changed = InfoBubble.prototype.maxWidth_changed;
InfoBubble.prototype.setMaxHeight = function(a) {
    this.set("maxHeight", a)
};
InfoBubble.prototype.setMaxHeight = InfoBubble.prototype.setMaxHeight;
InfoBubble.prototype.maxHeight_changed = function() {
    this.redraw_()
};
InfoBubble.prototype.maxHeight_changed = InfoBubble.prototype.maxHeight_changed;
InfoBubble.prototype.setMinWidth = function(a) {
    this.set("minWidth", a)
};
InfoBubble.prototype.setMinWidth = InfoBubble.prototype.setMinWidth;
InfoBubble.prototype.minWidth_changed = function() {
    this.redraw_()
};
InfoBubble.prototype.minWidth_changed = InfoBubble.prototype.minWidth_changed;
InfoBubble.prototype.setMinHeight = function(a) {
    this.set("minHeight", a)
};
InfoBubble.prototype.setMinHeight = InfoBubble.prototype.setMinHeight;
InfoBubble.prototype.minHeight_changed = function() {
    this.redraw_()
};
InfoBubble.prototype.minHeight_changed = InfoBubble.prototype.minHeight_changed;
InfoBubble.prototype.addTab = function(a, c) {
    var b = document.createElement("DIV");
    b.innerHTML = a;
    this.setTabStyle_(b);
    this.addTabActions_(b);
    this.tabsContainer_.appendChild(b);
    this.tabs_.push({
        label: a,
        content: c,
        tab: b
    });
    b.index = this.tabs_.length - 1;
    b.style.zIndex = this.baseZIndex_ - b.index;
    if (!this.activeTab_) {
        this.setTabActive_(b)
    }
    b.className = b.className + " " + this.animationName_;
    this.redraw_()
};
InfoBubble.prototype.addTab = InfoBubble.prototype.addTab;
InfoBubble.prototype.removeTab = function(a) {
    if (!this.tabs_.length || a < 0 || a >= this.tabs_.length) {
        return
    }
    var d = this.tabs_[a];
    d.tab.parentNode.removeChild(d.tab);
    google.maps.event.removeListener(d.tab.listener_);
    this.tabs_.splice(a, 1);
    // delete d;
    for (var c = 0, b; b = this.tabs_[c]; c++) {
        b.tab.index = c
    }
    if (d.tab == this.activeTab_) {
        if (this.tabs_[a]) {
            this.activeTab_ = this.tabs_[a].tab
        } else {
            if (this.tabs_[a - 1]) {
                this.activeTab_ = this.tabs_[a - 1].tab
            } else {
                this.activeTab_ = undefined
            }
        }
        this.setTabActive_(this.activeTab_)
    }
    this.redraw_()
};
InfoBubble.prototype.removeTab = InfoBubble.prototype.removeTab;
InfoBubble.prototype.getElementSize_ = function(b, e, c) {
    var d = document.createElement("DIV");
    d.style.display = "inline";
    d.style.position = "absolute";
    d.style.visibility = "hidden";
    if (typeof b == "string") {
        d.innerHTML = b
    } else {
        d.appendChild(b.cloneNode(true))
    }
    document.body.appendChild(d);
    var a = new google.maps.Size(d.offsetWidth, d.offsetHeight);
    if (e && a.width > e) {
        d.style.width = this.px(e);
        a = new google.maps.Size(d.offsetWidth, d.offsetHeight)
    }
    if (c && a.height > c) {
        d.style.height = this.px(c);
        a = new google.maps.Size(d.offsetWidth, d.offsetHeight)
    }
    document.body.removeChild(d);
    // delete d;
    return a
};
InfoBubble.prototype.redraw_ = function() {
    this.figureOutSize_();
    this.positionCloseButton_();
    this.draw()
};
InfoBubble.prototype.figureOutSize_ = function() {
    var t = this.get("map");
    if (!t) {
        return
    }
    var f = this.getPadding_();
    var u = this.getBorderWidth_();
    var b = this.getBorderRadius_();
    var j = this.getArrowSize_();
    var p = t.getDiv();
    var c = j * 2;
    var r = p.offsetWidth - c;
    var m = p.offsetHeight - c - this.getAnchorHeight_();
    var e = 0;
    var n = (this.get("minWidth") || 0);
    var h = (this.get("minHeight") || 0);
    var s = (this.get("maxWidth") || 0);
    var l = (this.get("maxHeight") || 0);
    s = Math.min(r, s);
    l = Math.min(m, l);
    var g = 0;
    if (this.tabs_.length) {
        for (var q = 0, a; a = this.tabs_[q]; q++) {
            var k = this.getElementSize_(a.tab, s, l);
            var d = this.getElementSize_(a.content, s, l);
            if (n < k.width) {
                n = k.width
            }
            g += k.width;
            if (h < k.height) {
                h = k.height
            }
            if (k.height > e) {
                e = k.height
            }
            if (n < d.width) {
                n = d.width
            }
            if (h < d.height) {
                h = d.height
            }
        }
    } else {
        var o = (this.get("content"));
        if (typeof o == "string") {
            o = this.htmlToDocumentFragment_(o)
        }
        if (o) {
            var d = this.getElementSize_(o, s, l);
            if (n < d.width) {
                n = d.width
            }
            if (h < d.height) {
                h = d.height
            }
        }
    }
    if (s) {
        n = Math.min(n, s)
    }
    if (l) {
        h = Math.min(h, l)
    }
    n = Math.max(n, g);
    if (n == g) {
        n = n + 2 * f
    }
    j = j * 2;
    n = Math.max(n, j);
    if (n > r) {
        n = r
    }
    if (h > m) {
        h = m - e
    }
    if (this.tabsContainer_) {
        this.tabHeight_ = e;
        this.tabsContainer_.style.width = this.px(g)
    }
    this.contentContainer_.style.width = this.px(n);
    this.contentContainer_.style.height = this.px(h)
};
InfoBubble.prototype.getAnchorHeight_ = function() {
    var b = 0;
    var a = this.get("anchor");
    if (a) {
        if (!b && a.height) {
            b = a.height
        }
        if (!b) {
            b = 34
        }
    }
    return b
};
InfoBubble.prototype.positionCloseButton_ = function() {
    var b = this.getBorderRadius_();
    var f = this.getBorderWidth_();
    var a = 2;
    var d = 2;
    if (this.tabs_.length && this.tabHeight_) {
        d += this.tabHeight_
    }
    d += f;
    a += f;
    var e = this.contentContainer_;
    if (e && e.clientHeight < e.scrollHeight) {
        a += 15
    }
    this.close_.style.right = this.px(a);
    this.close_.style.top = this.px(d)
};
});