(function ($) {
  Drupal.behaviors.saurEdmcWidget = {
    attach: function (context, settings) {
      $('select.saur-edmc-autocomplete', context).once('autocomplete', function () {
        $(this).select2({
          ajax: {
            url: $(this).data('uri'),
            allowClear: true,
            dataType: 'json',
            delay: 500,
            data: function (params) {
              return {
                query: params.term, // search term
                page: params.page,
                limit: 30
              };
            },
            processResults: function (data, params) {
              // parse the results into the format expected by Select2
              // since we are using custom formatting functions we do not need to
              // alter the remote JSON data, except to indicate that infinite
              // scrolling can be used
              params.page = params.page || 1;
              return {
                results: data.items,
                pagination: {
                  more: (params.page * 30) < data.total
                }
              };
            },
            cache: true
          },
          // We already did that in the PHP controller
          escapeMarkup: function (markup) { return markup; },
          minimumInputLength: 1,
          // No need for this, rendered in PHP side
          // templateResult: function () {},
          templateSelection: function (data) { return data.title || data.text; }
        });
      });

      if (Drupal.settings.saur_edmc && Drupal.settings.saur_edmc.suggestion_route) {
        $('select.select2-hidden-accessible', context).once('select2', function () {
          var element = this;
          var nid = Drupal.settings.saur_edmc.nid;
          var form_btn = $('button#form_submit');
          var redirection_btn = $('<a id="form_redirection" href="" class="btn-filled-white btn-arrow btn-water btn" style="display: none;">Accéder au site dédié</a>');

          $(form_btn).after(redirection_btn);
          $(element).on("change", function () {
            var value = $(this).val();
            $.ajax({
              type: 'GET',
              url: Drupal.settings.saur_edmc.suggestion_route.replace('00000', value) + (nid ? ('/' + nid) : ''),
              dataType: 'json',
              cache: false,
              success:function (data) {
                if (data.name && data.edmc_url) {
                  $('a#form_redirection').attr('href', data.edmc_url);
                  form_btn.hide();
                  redirection_btn.show();
                }
              },
              error:function (xhr){
                if(xhr.status==404) {
                  redirection_btn.hide();
                  form_btn.show();
                }
              }
            });
          });
        });
      }
    }
  };
}(jQuery));
