(function ($, document) {
  "use strict";

  Drupal.behaviors.saurFormAjaxValidation = {
    attach: function (context, settings) {
      $(context).find('button[data-groups]').on('click.formValidation', function (event) {

        var $this = $(this);
        var $form = null;

        if ($this.attr('form')) {
          $form = $('#' + $this.attr('form'));
        } else {
          $form = $this.closest('form');
        }

        if (!$form.length) {
          return;
        }

        var groups = $this.attr('data-groups').split(' ');
        var url = null;

        if ($this.attr('data-url')) {
          url = $this.attr('data-url');
          if (groups.length) {
            url += '?' + $.param({groups: groups});
          }
        } else {
          return;
        }

        event.preventDefault();

        var data = $form.serialize();

        $.ajax({
          type: 'POST',
          url: url,
          data: data,
          dataType: 'json',
          cache: false
        })
        .done(function (result) {
          if (result.succeed === true) {
            $this.trigger({
              type: 'saur-form:validation-success',
              groups: groups
            });
          } else {
            $this.trigger({
              type: 'saur-form:validation-failure',
              groups: groups,
              errors: result.errors
            });
          }
        });
        // .fail(function (jqXHR, textStatus, errorThrown) {
        //   console.log("Oups... A problem occured. What can I do?");
        // }
      });
    }
  }
}(jQuery, document));
