(function ($) {
  "use strict";

  Drupal.behaviors.saurCityValidation = {
    attach: function (context, settings) {

      var error = false;

      $('select[data-city-validation]').on('select2:select', function (event) {
        if (!event.params.data.isSaurCity) {
          if (error) {
            return;
          }

          error = Drupal.theme('saurFormError', "Nous ne distribuons pas l’eau de cette commune. Pour plus d’informations, nous vous recommandons de consulter les services de votre mairie.");

          $(this)
            // Inserts error message
            .parent()
            .append(error)
            // Applies error class
            .parents('.form-group')
            .addClass('has-error')
            // Disables all other inputs
            .parents('form')
            .find(':input')
            .not(this)
            .prop('disabled', true)
          ;
        }
        else if (error) {
          error = false;

          $(this)
            // Removes potential error message
            .parent()
            .find('.alert')
            .remove()
          ;

          $(this)
            // Removes potential error class
            .parents('.form-group')
            .removeClass('has-error')
            // Enables other inputs
            .parents('form')
            .find(':input')
            .prop('disabled', false)
          ;
        }
      });
    }
  };

}(jQuery));
