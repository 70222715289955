(function ($, document) {
  "use strict";

  Drupal.theme.prototype.saurFormError = function(message) {
    return '<span class="help-block"><ul class="list-unstyled"><li class="error-message"><span class="glyphicon glyphicon-exclamation-sign"></span> ' + message + '</li></ul></span>';
  };

  function selectHasValue(element) {
    var selected = element.selectedIndex;
    if ("undefined" !== typeof selected && element.options && element.options[selected]) {
      return element.options[selected].value;
    }
  }

  Drupal.behaviors.saurForm = {
    attach: function (context, settings) {
      $(context).find('form.magic-form').once('magic_johnson', function () {

        var form = $(this);
        var nextButtons = $('button[data-next-step]', form);
        var currentTarget = $('.first-panel', form);
        var currentTargetFields = currentTarget.find(':input');
        var currentTargetHasError = false;
        var end = $('#end');
        var hasScrolledAtLeastOnce = false;

        // Legal notice must be hidden until the city has been choosen and the
        // first hidden block was displayed. We need to initiliaze it at the
        // begining because this variable is in use in scrollToNextTarget().
        var hideIfNoCity = form.find('.hide-if-no-city');

        var triggerHtml5Validation = function () {
          // Trigger html5 validity of the current panel
          currentTargetFields.each(function () {
            $(this).parents('.form-group').removeClass('has-error');
            if (!this.checkValidity()) {
              $(this).parents('.form-group').addClass('has-error');
              currentTargetHasError = true;
            }
          });
        };

        var scrollToFirstErrorIn = function (selector) {
          var $errors = $('.has-error, alert-danger', selector);
          if ($errors.length) {
            hasScrolledAtLeastOnce = true;
            // Scroll to the first error of the panel
            setTimeout(function () {
              $('html, body').animate({
                scrollTop: $errors.eq(0).offset().top - 10
              }, 800);
            }, 600); // Delay for page init.
          }
          return false;
        };

        var scrollToFirstErrorOfValidationGroup = function () {
          // Focus on error if exists
          if (currentTargetHasError) {
            scrollToFirstErrorIn(currentTarget);
          }
        };

        var scrollToNextTarget = function (button) {
          if (!currentTargetHasError) {
            var $next = $(button.dataset.nextStep);
            if ($next.length) {
              var $button = $(button);

              // Show next panel, break if last one.
              $next.show('swing');
              if ('#end' === button.dataset.nextStep) {
                return;
              }

              // Real display procedure.
              $button.off('click').fadeTo('fast', 0.25);
              $('html, body').animate({
                scrollTop: $next.offset().top
              }, 800);
              currentTarget = $next;
              currentTargetFields = currentTarget.find(':input');

              // Huge hack, sorry.
              if ($button.is('[data-groups="Location"]')) {
                hideIfNoCity.show();
              }
            }
          }
        };

        var showSubmitPanel = function (currentIndex) {
          // Show end panel if we reach the last next button
          if (!currentTargetHasError && currentIndex === nextButtons.length - 2) {
            end.find(':submit').on('click', function (e) {
              e.preventDefault();
              nextButtons.eq(nextButtons.length - 1).trigger('click');
            }).end().show('swing');
          }
        };

        var addLoadingState = function (target) {
          if (!$('.fa-spin', target).length) {
            $(target).append('<i class="fa fa-refresh fa-spin"></i>');
            $('.form-group', currentTarget).fadeTo('swing', 0.5);
          }
        };

        var removeLoadingState = function (target) {
          $('.fa-spin', target).remove();
          $('.form-group', currentTarget).fadeTo('swing', 1);
        };

        var removeValidationErrors = function (target) {
          $(target).add('#end').find('.has-error').removeClass('has-error').end().find('.help-block').remove();
        };

        var displayAsyncValidationErrors = function (errors) {
          for (var field in errors) {
            var error = Drupal.theme('saurFormError', errors[field]);
            var re1 = new RegExp(/(\]|\[)+/g);
            var re2 = new RegExp(/_$/);
            var selector = '[data-helper-id="' + field.replace(re1, '_').replace(re2, '') + '"]';
            // Add error stuff to the field
            $(selector).addClass('has-error').append(error);
            // Invalidate the group
            currentTargetHasError = true;
          }

          scrollToFirstErrorOfValidationGroup();
        };

        var resetErrors = function () {
          currentTargetHasError = false;
          removeValidationErrors(currentTarget);
        };

        // Prevent html5 validation
        form.attr('novalidate', 'novalidate');

        // If at loading the form contains error we disable all
        // the next buttons behaviors
        if (form.find('.has-error, .alert-danger').length) {
          nextButtons.css('opacity', 0.25).click(function () {
            return false;
          });
          end.show();
        } else {
          // Hide submit section
          // to clear the form
          end.hide();

          // Next button behaviors
          nextButtons.each(function (index) {
            var selector = $(this).attr('data-next-step'); // Gets the segment
            var target = form.find(selector);
            var dataGroups = this.dataset.groups;
            var hasAsyncValidation = dataGroups ? true : false;

            // Store index data
            this.dataset.index = index;

            if (target.length) {
              target.hide();

              $(this).on('click.formNext', function (e) {
                e.preventDefault();

                // Remove existing errors
                resetErrors();

                // Abort if button has async validation
                // as it's handle by saur-form events
                if (hasAsyncValidation) {
                  // Add visual loading indicator
                  addLoadingState(this, selector);
                  return false;
                }

                // Handle basic html5 validation
                triggerHtml5Validation();
                scrollToFirstErrorOfValidationGroup();
                scrollToNextTarget(this);
                showSubmitPanel(index);
              });
            }
          });

          // Async validation response dispatcher
          form.on({
            'saur-form:validation-failure': function (event) {
              removeLoadingState(event.target);
              displayAsyncValidationErrors(event.errors);
            },
            'saur-form:validation-success': function (event) {
              removeLoadingState(event.target);
              if (event.target.dataset.nextStep) {
                scrollToNextTarget(event.target);
              }
              if (event.target.dataset.nextStep === '#end') {
                form.submit();
              } else if (event.target.dataset.index) {
                showSubmitPanel(event.target.dataset.index);
              }
            }
          });
        }

        scrollToFirstErrorIn(form);

        // Last-resort error placement
        if (form.find('#form-errors').length) {
          setTimeout(function () {
            if (!hasScrolledAtLeastOnce) {
              window.location.hash = 'form-errors';
            }
          }, 500);
        }

        // Handle address from select2 widget
        var citySelectElement = form.find('[name="saur_subscribe[location]"], [name="saur_contact_client[location]"]');
        if (citySelectElement.length) {
          var addrPostalCodeElement = form.find('[name="saur_subscribe[contract_address][postal_code]"], [name="saur_contact_client[address][postal_code]"]');
          var addrCityElement = form.find('[name="saur_subscribe[contract_address][locality]"], [name="saur_contact_client[address][locality]"]');
          // var addrCountryElement =
          // form.find('[name="saur_subscribe[contract_address][country]"]');
          if (addrPostalCodeElement.length || addrCityElement.length) {
            citySelectElement.on("select2:select", function (event) {
              if (event.params && event.params.data) {
                var data = event.params.data;
                if (data.text) {
                  var items = data.text.split(" - ");
                  if (1 < items.length) {
                    addrPostalCodeElement.val(items[0]);
                    addrPostalCodeElement.attr({readonly: "readonly"});
                    addrCityElement.val(items[1]);
                    addrCityElement.attr({readonly: "readonly"});
                  }
                }
              }
            });
          }
          if (selectHasValue(citySelectElement.get(0))) {
            addrPostalCodeElement.attr({readonly: "readonly"});
            addrCityElement.attr({readonly: "readonly"});
          }
        } else {
          hideIfNoCity.show();
        }

        // Handle societe/individual toggle
        var radiosSelector = [
          '[name="saur_subscribe[client_type]"]',
          '[name="saur_contact[client_type]"]',
          '[name="saur_contact_client[client_type]"]',
          '[name="saur_termination[client_type]"]',
        ].join(', ');

        var companyIndividualElement = form.find(radiosSelector);
        if (companyIndividualElement.length) {
          var hideIfCompany = form.find('.hide-if-company');
          var hideIfIndividual = form.find('.hide-if-individual');
          companyIndividualElement.on("change", function () {
            var radio = $(this);
            if (radio.is(':checked')) {
              if ("Particulier" === radio.val()) {
                hideIfIndividual.hide();
                hideIfCompany.show();
              } else {
                hideIfCompany.hide();
                hideIfIndividual.show();
              }
            }
          });
          companyIndividualElement.trigger("change");
        }

        // Handle co-owner
        var coownerElement = form.find('[name="saur_subscribe[contract_coholder_switch]"]');
        if (coownerElement.length) {
          var hideIfNoCoowner = form.find('.hide-if-no-co-owner');
          coownerElement.on("change", function () {
            if ($(this).is(':checked')) {
              hideIfNoCoowner.show();
            } else {
              hideIfNoCoowner.hide();
            }
          });
          coownerElement.trigger("change");
        }

        // Handle co-owner
        var previousElement = form.find('[name="saur_subscribe[contract_previous_owner_switch]"]');
        if (previousElement.length) {
          var hideIfNoPrevious = form.find('.hide-if-no-previous');
          previousElement.on("change", function () {
            if ($(this).is(':checked')) {
              hideIfNoPrevious.show();
            } else {
              hideIfNoPrevious.hide();
            }
          });
          previousElement.trigger("change");
        }

        // Handle billing information
        var billingDifferentElement = form.find('[name="saur_subscribe[contract_billing_address_switch]"]');
        if (billingDifferentElement.length) {
          var showIfBillingDifferent = form.find('.show-if-billing-different');
          billingDifferentElement.on("change", function () {
            if ($(this).is(':checked')) {
              showIfBillingDifferent.show();
            } else {
              showIfBillingDifferent.hide();
            }
          });
          billingDifferentElement.trigger("change");
        }

        // Handle bank account number
        var directDebitMethodRadio = form.find('[name="saur_subscribe[payment_method][direct_debit]"]');
        var otherMethodRadio = form.find('[name="saur_subscribe[payment_method][other_method]"]');
        if (directDebitMethodRadio.length) {
          var showIfDirectDebit = form.find('.show-if-direct-debit');
          var hideIfDirectDebit = form.find('.hide-if-direct-debit');
          var hideIfPayementOther = form.find('.hide-if-payement-other');
          // http://stackoverflow.com/a/323969
          $().add(directDebitMethodRadio).add(otherMethodRadio).on("change", function () {
            if ("autre" === directDebitMethodRadio.filter(':checked').val()) {
              hideIfPayementOther.hide();
              if ('echeance' !== otherMethodRadio.filter(':checked').val()) {
                showIfDirectDebit.hide();
                hideIfDirectDebit.show();
              } else {
                hideIfDirectDebit.show();
                showIfDirectDebit.show();
              }
            } else {
              hideIfPayementOther.show();
              hideIfDirectDebit.hide();
              showIfDirectDebit.show();
            }
          });
          directDebitMethodRadio.trigger("change");
        }

        // Efacture
        var efactureCheckbox = form.find('[name="saur_subscribe[payment_method][efacture]"]');
        if (efactureCheckbox.length) {
          var showIfEFacture = form.find('.show-if-efacture');
          efactureCheckbox.on("change", function () {
            if ($(this).is(':checked')) {
              showIfEFacture.show();
            } else {
              showIfEFacture.hide();
            }
          });
          efactureCheckbox.trigger("change");
        }

        // Handle cost estimate
        var costEstimateAdjustElement = form.find('[name="saur_subscribe[cost_estimate_adjust_switch]"]');
        if (costEstimateAdjustElement.length) {
          var showIfCostEstimateAdjust = form.find('.show-if-cost-estimate-adjust');
          costEstimateAdjustElement.on("change", function () {
            if ($(this).is(':checked')) {
              showIfCostEstimateAdjust.show();
            } else {
              showIfCostEstimateAdjust.hide();
            }
          });
          costEstimateAdjustElement.trigger("change");
        }

        // E-facture, lorsque le case "je souhaite e-facture" est cochée par
        // l'utilisateur, on décoche l'acceptation des conditions générales
        // pour le forcer à les accepter explicitement.
        (function () {
          var eFactureConditionElement = form.find('[name="saur_subscribe[payment_method][efacture_optin]"]');
          var directDebitSwitchElement = form.find('[name="saur_subscribe[payment_method][direct_debit]"]');
          if (eFactureConditionElement.length) {
            // Initialize unchecked.
            eFactureConditionElement.get(0).checked = false;
            var eFactureTriggerElement = form.find('[name="saur_subscribe[payment_method][efacture]"]');
            if (eFactureTriggerElement.length) {
              eFactureTriggerElement.get(0).onchange = function () {
                eFactureConditionElement.get(0).checked = false;
              };
            }
            // Lorsque l'utilisateur change de moyen de paiement, on doit aussi
            // remettre cette valeur par défaut.
            if (directDebitSwitchElement.length) {
              directDebitSwitchElement.on('change', function () {
                eFactureTriggerElement.get(0).checked = false;
                eFactureConditionElement.get(0).checked = false;
              });
            }
          }
        }());

        // Cost estimate display
        (function () {

          var computed = form.find('[name="saur_subscribe[cost_estimate_computed]"]');
          var nbPersonsElement = form.find('[name="saur_subscribe[contract_human_number]"]');
          var estimationLabel = form.find('.cost-estimate-label');
          var textTemplate = estimationLabel.html();
          var selectChoices = form.find('[name="saur_subscribe[cost_estimate_adjust_delta]"]');

          if (nbPersonsElement.length) {

            function computeValue(persons) {
              return Math.round(persons * 3.4 * 40 / 12); // @todo variabalize
            }

            function updateForm() {
              var nbPerson = nbPersonsElement.val();
              var value = computeValue(nbPerson);

              computed.val(value);

              var text = textTemplate.replace('[persons]', nbPerson).replace('[euros]', value);
              estimationLabel.html(text);

              selectChoices.find('[value="-1"]').html(Math.round(value * 0.9));
              selectChoices.find('[value="1"]').html(Math.round(value * 1.1));
            }

            nbPersonsElement.on('change', function () {
              updateForm();
              estimationLabel.show();
            });

            nbPersonsElement.trigger('change');
          }
          else {
            estimationLabel.hide();
          }

        }());

        // Handle Get method on contact-client
        var location_select = form.find('select#saur_contact_client_location');
        if (location_select.length) {
          if (location_select.val()) {
            // var button =
            // form.find('[data-next-step="#information-personnelles"]');
            var buttonNext = form.find('button[data-groups="Location"]');
            scrollToNextTarget(buttonNext[0]);
          }
        }
      });
    }
  };
}(jQuery, document));
      