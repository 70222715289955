
const STORAGE_KEY_HIDDEN = "alerte-flash-hidden";

class Button {
    readonly element: HTMLButtonElement;
    readonly container: HTMLElement;
    readonly target: string;
    readonly storageKey: string;
    hidden: boolean;

    constructor(element: HTMLButtonElement) {
        this.element = element;
        this.target = element.getAttribute("data-target") as string;
        this.storageKey = STORAGE_KEY_HIDDEN + "-" + this.target;
        this.container = element.parentElement as HTMLElement;
        this.hidden = parseInt(window.localStorage.getItem(this.storageKey) || "0", 10) ? true : false;
    }

    show(): void {
        this.container.style.display = "block";
        this.container.style.visibility = "visible";
        this.hidden = false;
    }

    hide(): void {
        this.container.style.display = "none";
        this.container.style.visibility = "hidden";
        this.hidden = true;
    }
}

document.addEventListener("DOMContentLoaded", event => {
    const elements = document.querySelectorAll(`#alertes-flash`);

    for (let i = 0; i < elements.length; ++i) {
        const element = elements[i] as HTMLElement;
        const isHome = parseInt(element.getAttribute("data-is-home") || "0", 10);
        let allHidden = false;

        // Parse DOM to find all buttons state
        const nodes = element.querySelectorAll("[data-target]");
        for (let i = 0; i < nodes.length; i++) {
            const button = new Button(nodes[i] as HTMLButtonElement);

            button.element.addEventListener("click", event => {
                button.hide();
                window.localStorage.setItem(button.storageKey, "1");
            });

            if (button.hidden && !isHome) {
                button.hide();
            } else {
                button.show();
                allHidden = false;
            }
        }

        if (isHome) {
            // Fonctionnement demandé: lorsqu'un utilisateur revient sur la
            // home, on affiche de nouveau les alertes. Par contre on ne reset
            // jamais l'affichage ou non, si l'utilisateur les masque, c'est
            // pour TOUJOURS (cherchez pas).
            // J'aurais pu faire un if (isHome || !hidden) ici, mais je préfère
            // conserver explicitement les deux cas, on sait jamais ce qu'ils
            // vont nous demander lors de la recette.
            element.style.display = "block";
        } else if (allHidden) {
            element.style.display = "none";
            element.style.visibility = "hidden";
        } else {
            element.style.display = "block";
        }
    }
});
