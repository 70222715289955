(function ($) {
    "use strict";

    /**
     * Shuffle elements plugin
     * @author Chris Coyier
     * @see https://css-tricks.com/snippets/jquery/shuffle-children/
     */
    $.fn.shuffleChildren = function () {
        $.each(this.get(), function (index, el) {
            var $el = $(el);
            var $find = $el.children();

            $find.sort(function () {
                return 0.5 - Math.random();
            });

            $el.empty();
            $find.appendTo($el);
        });

        return this;
    };

    /**
     * Initialize Slick carousel
     */
    var saurCarousel = function () {

        var $slides = $('.slide-top').shuffleChildren(),
            $slidesItems = $slides.find('.item'),
            $controls = $('.slide-top-control'),
            $controlItems = $controls.find('.item'),
            randControls = [];

        (function () {
            $slidesItems.each(function () {
                var $slide = $(this),
                    slideNid = $slide.data('nid');

                $controlItems.each(function () {
                    var $control = $(this),
                        controleNid = $control.data('nid');

                    if (controleNid === slideNid) {
                        randControls.push($control);
                    }
                });

            });
        }());

        $controls.html(randControls);

        $slides.slick({
            infinite: true,
            arrows: false,
            fade: true,
            asNavFor: '.slide-top-control',
            autoplay: true,
            autoplaySpeed: 5000
        });

        $controls.slick({
            infinite: true,
            arrows: false,
            slidesToShow: 10,
            slidesToScroll: 1,
            variableWidth: true,
            asNavFor: '.slide-top',
            focusOnSelect: true
        });
    };

    /**
     * Assistance menu handler
     */
    var saurHelpMenu = function () {

        var $needHelp = $('#need-help');
        $needHelp.find('.open').click(function (e) {
            e.preventDefault();
            $(this).addClass('opened')
                .next('a').fadeIn()
                .next('div').addClass('opened');
        });

        $needHelp.find('.btn-close').click(function (e) {
            e.preventDefault();
            $needHelp.find('.open').removeClass('opened');
            $(this).fadeOut().next('div').removeClass('opened');
        });
    };

    /**
     * Bootstrap affix behavior for left-nav menu
     * @see #13697
     * It still need works, the left-nav should be
     * placed in a sub wrapper without grid value
     * in order to work
     */
    var bootstrapAffix = function () {
        if ($.fn.affix && $(document).width() >= 970) {
            $('.nav-left').affix({
                offset: {
                    top: function () {
                        var top = 0;
                        // console.log('top', top);
                        return (this.top = top);
                    },
                    bottom: function () {
                        var bottom = $('.home-row4').height() + $('.search-bottom').height() + $('.footer').height();
                        // console.log('bottom', bottom);
                        return (this.bottom = bottom);
                    }
                }
            });
        }
    };

    $(document).ready(function () {
        saurCarousel();
        saurHelpMenu();
        // bootstrapAffix();
    });

}(jQuery));
